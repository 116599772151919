<script lang="ts" setup>
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import { storeToRefs } from 'pinia';

import * as client from '@gabrielcam/api-client';

import { getIconComponent } from '@utils/heroIconsMap';
import { useMobileDetection } from '@utils/isMobile';

import { useApplicationStore } from '@stores/application';
import { useNavStore } from '@stores/navMenu';
import { IconName, IconStyle } from '@viewModels/heroIcons';

import { Bars4Icon, ChevronDownIcon, XMarkIcon } from '@heroicons/vue/24/solid';

const navStore = useNavStore();
const applicationStore = useApplicationStore();
const { navItems } = storeToRefs(navStore);
const user = applicationStore.activeUser;
const { organisationList, activeOrganisation } = storeToRefs(applicationStore);
const route = useRoute();
const selectedOrganisation = ref<client.Organisation>(activeOrganisation.value!);
const mobileNavOpen = ref<boolean>(false);
const { isMobile } = useMobileDetection();

// Filter nav items based on permissions
const filteredNavItems = computed(() => {
  return navItems.value
    .filter((item) => {
      // Show the item if:
      // - `viewPermission` is undefined (accessible to all users)
      // - `viewPermission` is true and the user has admin permissions
      return item.viewPermission === undefined || item.viewPermission;
    })
    .map((item) => {
      // Same Logic for Nav children
      if (item.children) {
        item.children = item.children.filter((child) => child.viewPermission === undefined || child.viewPermission);
      }
      return item;
    });
});

// Toggle mobile nav and pass state to navStore
function toggleMobileNav(): void {
  // Check if mobile
  if (isMobile) {
    mobileNavOpen.value = !mobileNavOpen.value;
    const appContainer = document.getElementById('appContainer');
    if (appContainer) {
      if (mobileNavOpen.value) {
        appContainer.classList.add('mobile-nav-open');
      } else {
        appContainer.classList.remove('mobile-nav-open');
      }
    } else {
      console.error('Ensure element id "appContainer" exists in the DOM for the mobile nav to work');
    }
  }
}

// Close mobile nav when a route is clicked
function mobileLink(): void {
  setTimeout(() => {
    toggleMobileNav();
  }, 200);
}

function organisationSelected(): void {
  applicationStore.setActiveOrganisation(selectedOrganisation.value!.id);
}

// Handle clicks outside the sidebar
function handleOutsideClick(event: MouseEvent): void {
  const sidebar = document.querySelector('.app--sidebar');

  if (sidebar && !sidebar.contains(event.target as Node)) {
    toggleMobileNav();
  }
}

// Stop body scrolling when the sidebar is open
watch(mobileNavOpen, (newValue) => {
  if (newValue) {
    document.body.classList.add('no-scroll');
    document.addEventListener('click', handleOutsideClick);
  } else {
    document.body.classList.remove('no-scroll');
    document.removeEventListener('click', handleOutsideClick);
  }
});

// Ensure IDs are sanitized
function sanitizeId(title: string): string {
  return title.replace(/[^a-zA-Z0-9-_:.]/g, '-');
}

// Opens the parent menus based on the route
function openParentMenus(currentRoute: string): void {
  const filteredItems = filteredNavItems.value.filter(
    (item) => item.children && item.children.some((child) => currentRoute.startsWith(child.to || ''))
  );

  filteredItems.forEach((item) => {
    item.isOpen = true;
    nextTick(() => {
      const subnav = document.querySelector(`#subnav-${sanitizeId(item.title as string)}`);
      if (subnav) {
        (subnav as HTMLElement).style.maxHeight = `${subnav.scrollHeight}px`;
      } else {
        console.warn(`subnav ref not found for ${item.title}`);
      }
    });
  });
}

// Set the main menu on component mount
navStore.setMainMenu();

// Open parent menus on component mount
onMounted(() => {
  setTimeout(() => {
    openParentMenus(route.path);
  }, 100);
});

// Watch for route changes to open parent menus
watch(route, (newRoute) => {
  openParentMenus(newRoute.path);
});

// Remove event listener when component is destroyed
onBeforeUnmount(() => {
  document.removeEventListener('click', handleOutsideClick);
});
</script>

<template>
  <header>
    <router-link class="header-logo"
                 data-title="views"
                 to="/views">
      GabrielCAM
    </router-link>
  </header>

  <div class="sidebar-container">
    <router-link aria-label="GabrielCAM"
                 class="app--sidebar-logo app--sidebar-logo-open"
                 data-title="views"
                 to="/views" />

    <button :aria-expanded="mobileNavOpen"
            aria-controls="menu-container"
            aria-label="Toggle Menu"
            class="menu-toggle button-icon button-icon--navbar"
            @click="toggleMobileNav">
      <Bars4Icon v-show="!mobileNavOpen"
                 aria-hidden="true" />
      <XMarkIcon v-show="mobileNavOpen"
                 aria-hidden="true" />
    </button>

    <nav id="menu-container"
         class="menu-container">
      <ul class="menu menu-primary">
        <li v-if="organisationList && organisationList.length === 1"
            class="menu-organisation">
          {{ activeOrganisation?.name }}
        </li>

        <v-select v-else-if="organisationList && organisationList.length > 1"
                  v-model="selectedOrganisation"
                  :clearable="false"
                  :options="organisationList"
                  class="menu-organisation-select"
                  label="name"
                  placeholder="Name"
                  @option:selected="organisationSelected" />

        <li v-for="item in filteredNavItems"
            :key="item.title">
          <template v-if="item.separator">
            <hr>
          </template>
          <template v-else-if="item.children">
            <template v-if="item.isDropdown !== false">
              <button class="menu-dropdown"
                      @click="item.isOpen = !item.isOpen">
                <component :is="getIconComponent(item.icon, item.iconStyle)"
                           v-if="item.icon"
                           class="menu-icon" />
                <span class="menu-header-text">{{ item.title }}</span>
                <ChevronDownIcon :class="{ 'rotate-180': item.isOpen }"
                                 class="arrow-icon" />
              </button>
              <ul v-show="item.isOpen"
                  :id="`subnav-${sanitizeId(item.title as string)}`"
                  class="submenu">
                <li v-for="child in item.children"
                    :key="child.title">
                  <template v-if="child.to">
                    <router-link :data-title="child.title"
                                 :to="child.to"
                                 class="menu-item"
                                 @click="mobileLink">
                      <component :is="getIconComponent(child.icon, item.iconStyle)"
                                 v-if="child.icon"
                                 class="menu-icon" />
                      {{ child.title }}
                    </router-link>
                  </template>
                  <template v-else>
                    <div class="menu-item">
                      <component :is="getIconComponent(child.icon, item.iconStyle)"
                                 v-if="child.icon"
                                 class="menu-icon" />
                      {{ child.title }}
                    </div>
                  </template>
                </li>
              </ul>
            </template>
            <template v-else>
              <template v-if="item.to">
                <router-link :to="item.to"
                             class="menu-header"
                             @click="mobileLink">
                  <component :is="getIconComponent(item.icon, item.iconStyle)"
                             v-if="item.icon"
                             class="menu-icon" />
                  <span class="menu-header-text">{{ item.title }}</span>
                </router-link>
              </template>
              <template v-else>
                <div class="menu-header">
                  <component :is="getIconComponent(item.icon, item.iconStyle)"
                             v-if="item.icon"
                             class="menu-icon" />
                  <span class="menu-header-text">{{ item.title }}</span>
                </div>
              </template>
              <ul :id="`subnav-${item.title}`"
                  :class="{ 'no-dropdown': !item.isDropdown && item.isOpen }"
                  class="submenu">
                <li v-for="child in item.children"
                    :key="child.title">
                  <template v-if="child.to">
                    <router-link :data-title="child.title"
                                 :to="child.to"
                                 class="menu-item"
                                 @click="mobileLink">
                      <component :is="getIconComponent(child.icon, item.iconStyle)"
                                 v-if="child.icon"
                                 class="menu-icon" />
                      {{ child.title }}
                    </router-link>
                  </template>
                  <template v-else>
                    <div class="menu-item">
                      <component :is="getIconComponent(child.icon, item.iconStyle)"
                                 v-if="child.icon"
                                 class="menu-icon" />
                      {{ child.title }}
                    </div>
                  </template>
                </li>
              </ul>
            </template>
          </template>
          <template v-else-if="item.to">
            <router-link :data-title="item.title"
                         :to="item.to"
                         class="menu-item"
                         @click="mobileLink">
              <component :is="getIconComponent(item.icon, item.iconStyle)"
                         v-if="item.icon"
                         class="menu-icon" />
              {{ item.title }}
            </router-link>
          </template>
          <template v-else>
            <div class="menu-item">
              <component :is="getIconComponent(item.icon, item.iconStyle)"
                         v-if="item.icon"
                         class="menu-icon" />
              {{ item.title }}
            </div>
          </template>
        </li>
      </ul>

      <div class="menu menu-secondary">
        <div class="menu-footer">
          <router-link data-title="Account"
                       to="/account"
                       @click="mobileLink">
            <component :is="getIconComponent(IconName.UserCircleIcon, IconStyle.Solid)"
                       class="footer-icon" />
            {{ user?.displayName ?? 'Account' }}
          </router-link>
        </div>
      </div>
    </nav>
  </div>

  <div v-if="mobileNavOpen"
       class="overlay"
       @click="toggleMobileNav" />
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.button-icon {
  &--navbar {
    background: unset;
    border: unset;
    outline: unset;
    outline-offset: unset;

    &:hover,
    &:focus,
    &:active,
    &:focus-visible {
      background: unset;
      color: unset;
      outline: unset;
      outline-offset: unset;
    }

    &:hover svg,
    &:focus svg,
    &:focus-visible svg {
      fill: white;
    }
  }
}

@media screen and (max-width: $breakpoint-lg) {
  .overlay {
    background: $black-opacity-75 url(/src/assets/background.png) top left;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity 0.15s;
    z-index: 12;
  }

  .sidebar-open .overlay {
    opacity: 1;
    transition: opacity 0.15s;
    visibility: visible;
  }
}
</style>
