<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';

import * as client from '@gabrielcam/api-client';

import { useApplicationStore } from '@stores/application';
import { PageNames } from '@viewModels/enums';

import ContainerCard from '@components/cards/ContainerCard.vue';
import SubHeader from '@components/SubHeader.vue';

interface ClientCreationForm {
  systemSerialNumber: string;
  wpSerialNumber: string;
  wpImei: string;
}

const schema = yup.object({
  systemSerialNumber: yup.string().required(),
  wpSerialNumber: yup.string().required(),
  wpImei: yup.string().required(),
});

const { handleSubmit } = useForm<ClientCreationForm>({
  validationSchema: schema,
});

const { value: systemSerialNumberValue, errorMessage: systemSerialNumberError } = useField<string>(
  'systemSerialNumber',
  'systemSerialNumber'
);
const { value: wpSerialNumberValue, errorMessage: wpSerialNumberError } = useField<string>(
  'wpSerialNumber',
  'wpSerialNumber'
);
const { value: wpImeiValue, errorMessage: wpImeiError } = useField<string>('wpImei', 'wpImei');

const router = useRouter();
const applicationStore = useApplicationStore();
const isSubmitting = ref<boolean>(false);

const onSubmit = handleSubmit(async (values: any) => {
  isSubmitting.value = true;
  if (applicationStore.activeOrganisation == undefined) return;

  try {
    await client.createCamera({
      requestBody: {
        serialNumber: values.systemSerialNumber,
        systemType: client.SupportedSystems.GABRIELCAMV1,
        meta: {
          wpImei: values.wpImei,
          wpSerialNumber: values.wpSerialNumber,
        },
      },
    });
    applicationStore.publishSuccessNotification({
      text: 'Successfully created camera.',
      autoCloseMs: 3000,
    });
    router.push({ name: PageNames.Cameras });
  } catch (error) {
    if (error instanceof client.ApiError) {
      // @ts-ignore
      applicationStore.publishErrorNotification({ text: error.body.error.message });
    }
    applicationStore.publishErrorNotification({ text: 'UNKNOWN ERROR' });
    isSubmitting.value = false;
    return;
  }
});
</script>

<template>
  <SubHeader heading="Create Camera"
             level="2" />

  <ContainerCard>
    <form @submit="onSubmit">
      <div class="field-group">
        <div class="field-group-info">
          <h3>Camera Information</h3>
          <p>Add your camera details and register it in the system.</p>
        </div>
        <div class="fields">
          <div class="row-half">
            <div class="field">
              <label for="camera-serial">GabrielCam Serial Number</label>
              <input id="system-serial"
                     v-model="systemSerialNumberValue"
                     inputmode="numeric"
                     type="text">
              <p class="message message-error">
                {{ systemSerialNumberError }}
              </p>
            </div>
          </div>
          <div class="row-half">
            <div class="field">
              <label for="camera-serial">Serial Number</label>
              <input id="wp-serial"
                     v-model="wpSerialNumberValue"
                     inputmode="numeric"
                     type="text">
              <p class="message message-error">
                {{ wpSerialNumberError }}
              </p>
            </div>
            <div class="field">
              <label for="camera-imei">IMEI</label>
              <input id="wp-imei"
                     v-model="wpImeiValue"
                     inputmode="numeric"
                     type="text">
              <p class="message message-error">
                {{ wpImeiError }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="form-buttons">
        <button class="button"
                :disabled="isSubmitting">
          Create
        </button>
      </div>
    </form>
  </ContainerCard>
</template>
