<script setup lang="ts">
import { ref } from 'vue';
import { useRoute } from 'vue-router';

import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';

import * as client from '@gabrielcam/api-client';

import { useApplicationStore } from '@stores/application';

interface OrganisationUpdateForm {
  name: string;
  primaryColour: string;
  secondaryColour: string;
  logo: string;
}
const schema = yup.object({
  name: yup.string().required(),
  primaryColour: yup.string().nullable(),
  secondaryColour: yup.string().nullable(),
  logo: yup.string().nullable(),
});

const { handleSubmit } = useForm<OrganisationUpdateForm>({
  validationSchema: schema,
});

const { value: nameValue, errorMessage: nameError } = useField<string>('name', 'name');
const { value: primaryColourValue, errorMessage: primaryColourError } = useField<string>(
  'primaryColour',
  'primaryColour'
);
const { value: secondaryColourValue, errorMessage: secondaryColourError } = useField<string>(
  'secondaryColour',
  'secondaryColour'
);
const { value: logoValue, errorMessage: logoError } = useField<string>('logo', 'logo');

const route = useRoute();
const applicationStore = useApplicationStore();

const isSubmitting = ref<boolean>(false);
const organisationId = route.params['id'] as string;
const currentOrganisation = await client.getOrganisationById({ organisationId });
nameValue.value = currentOrganisation.name;
primaryColourValue.value = currentOrganisation.settings?.primaryColour ?? applicationStore.defaultTheme.primaryColour;
secondaryColourValue.value =
  currentOrganisation.settings?.secondaryColour ?? applicationStore.defaultTheme.secondaryColour;
logoValue.value = currentOrganisation.settings?.logoBase64 ?? applicationStore.defaultTheme.logo;

const onSubmit = handleSubmit(async (values) => {
  isSubmitting.value = true;
  const requestBody: client.UpdateOrganisationRequest = {
    name: values.name,
    settings: {
      logoBase64: values.logo,
      primaryColour: values.primaryColour,
      secondaryColour: values.secondaryColour,
    },
  };
  try {
    await client.updateOrganisationById({ organisationId, requestBody });
    applicationStore.publishSuccessNotification({
      text: 'Successfully updated organisation.',
      autoCloseMs: 3000,
    });
  } catch (error) {
    if (error instanceof client.ApiError) {
      // @ts-ignore
      applicationStore.publishErrorNotification({ text: error.body.error.message });
    } else {
      applicationStore.publishErrorNotification({ text: 'UNKNOWN ERROR' });
    }
  } finally {
    isSubmitting.value = false;
  }

  if (organisationId === applicationStore.activeOrganisation?.id) {
    await applicationStore.setActiveOrganisation(applicationStore.activeOrganisation.id);
  }
});

async function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve((reader.result as string | null) || '');
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
}

async function handleFileChange(event: Event): Promise<void> {
  const inputElement = event.target as HTMLInputElement;
  if (inputElement.files == undefined) return;
  const file = inputElement.files[0];
  if (file) {
    logoValue.value = await fileToBase64(file);
  }
}
</script>

<template>
  <form @submit="onSubmit">
    <div class="field-group">
      <div class="field-group-info">
        <h3>Organisation Information</h3>
        <p>Update a organisation and customise their experience with their logo and a theme.</p>
      </div>
      <div class="fields">
        <div class="field">
          <label for="view-name">Name</label>
          <input id="organisation-name"
                 v-model="nameValue"
                 type="text">
          <p class="message message-error">
            {{ nameError }}
          </p>
        </div>

        <div class="row-third">
          <div class="field">
            <label for="view-name">Logo</label>
            <input type="file"
                   @change="handleFileChange">
            <p class="message message-error">
              {{ logoError }}
            </p>
          </div>
          <div class="field">
            <label for="model">Primary Colour</label>
            <input id="organisation-primaryColour"
                   v-model="primaryColourValue"
                   type="color"
                   class="theme-primary-color">
            <p class="message message-error">
              {{ primaryColourError }}
            </p>
          </div>
          <div class="field">
            <label for="model">Secondary Colour</label>
            <input id="organisation-secondaryColour"
                   v-model="secondaryColourValue"
                   type="color"
                   class="theme-secondary-color">
            <p class="message message-error">
              {{ secondaryColourError }}
            </p>
          </div>
        </div>
        <div class="theme-preview">
          <div class="theme-preview-sidebar"
               :style="{ 'background-color': primaryColourValue }">
            <img v-if="logoValue"
                 class="theme-preview-logo"
                 :src="logoValue"
                 alt="Current logo">
          </div>
          <div class="theme-preview-content"
               :style="{ 'background-color': secondaryColourValue }" />
        </div>
      </div>
    </div>
    <div class="form-buttons">
      <button class="button"
              :disabled="isSubmitting">
        Update
      </button>
    </div>
  </form>
</template>
