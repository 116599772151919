<script lang="ts" setup>
import { computed, ref } from 'vue';

import { AlertVariant, FetchStatus, StepStatus } from '@viewModels/enums';
import { IconName, IconStyle } from '@viewModels/heroIcons';

import AlertBanner from '@components/AlertBanner.vue';

import { ArrowPathIcon, CheckIcon, ExclamationCircleIcon } from '@heroicons/vue/24/outline';

// Interface
export interface MultiStepItems {
  label: string;
  status: StepStatus;
  fetchStatus?: FetchStatus;
}

// Props
const props = defineProps<{
  steps: MultiStepItems[];
}>();

// Deconstruct props
const steps = ref<MultiStepItems[]>(props.steps);

const statusMessage = ref();

// Get the next StepStatus to style the HR accordingly
const getNextStepStatus = (index: number): StepStatus => {
  return steps.value[index + 1]?.status ?? StepStatus.Default;
};

// Show Status to User
const overallStatusMessage = computed((): string => {
  const isError = steps.value.some((step) => step.fetchStatus === FetchStatus.Error);
  const isSuccess = steps.value.every((step) => step.fetchStatus === FetchStatus.Success);

  if (isError) {
    return 'Error: Some steps failed. Please try again.';
  } else if (isSuccess) {
    return 'Success: All steps completed successfully!';
  } else {
    return 'Pending: Some steps are still processing. Please wait.';
  }
});

// Option to Close the Banner
const closeBanner = (bannerRefName: 'status-banner'): void => {
  if (bannerRefName === 'status-banner' && statusMessage.value) {
    statusMessage.value.closeNotificationBar();
    // Reset Status Message
    statusMessage.value = '';
  }
};
</script>

<template>
  <div class="multi-step">
    <div v-for="(step, index) in steps"
         :key="index"
         class="multi-step__container">
      <div class="multi-step__item"
           :class="{
             'multi-step__item--completed': step.status === StepStatus.Completed,
             'multi-step__item--pending': step.status === StepStatus.Pending,
             'multi-step__item--error': step.status === StepStatus.Error,
           }">
        <div class="multi-step__icon">
          <template v-if="step.status === StepStatus.Completed">
            <CheckIcon class="multi-step__icon--completed" />
          </template>

          <template v-else-if="step.status === StepStatus.Pending">
            <ArrowPathIcon class="multi-step__icon--pending" />
          </template>

          <template v-else-if="step.status === StepStatus.Error">
            <ExclamationCircleIcon class="multi-step__icon--error" />
          </template>

          <template v-else>
            <span class="multi-step__icon--number">{{ index + 1 }}</span>
          </template>
        </div>
        <span :title="step.label + ' ' + step.fetchStatus"
              class="multi-step__label">{{ step.label }} </span>
      </div>

      <!-- Horizontal line based on next step's status -->
      <hr v-if="index < steps.length - 1"
          :class="{
            'multi-step__line--completed': getNextStepStatus(index) === StepStatus.Completed,
            'multi-step__line--pending': getNextStepStatus(index) === StepStatus.Pending,
            'multi-step__line--default': getNextStepStatus(index) === StepStatus.Default,
            'multi-step__line--error': getNextStepStatus(index) === StepStatus.Error,
          }"
          class="multi-step__line">
    </div>
  </div>

  <AlertBanner v-if="overallStatusMessage"
               ref="status-banner"
               :has-close="true"
               :icon-name="IconName.CheckCircleIcon"
               :icon-style="IconStyle.Outline"
               :variant="AlertVariant.Danger"
               @close="closeBanner('status-banner')">
    <template #mainContent>
      {{ overallStatusMessage }}
    </template>
  </AlertBanner>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.multi-step {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  margin-bottom: $margin-bottom;
  overflow: hidden;
  position: relative;

  &__container {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: $margin-bottom;
    position: relative;
  }

  &__item {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    row-gap: 10px;
    z-index: 1;

    &--completed .multi-step__icon {
      background-color: $green-900;
      color: $neutral-50;
    }

    &--pending .multi-step__icon {
      background-color: $blue-900;
      color: $neutral-50;
    }

    &--error .multi-step__icon {
      background-color: $red-900;
      color: $neutral-50;
      padding: 7px;
    }
  }

  &__icon {
    align-items: center;
    background-color: $neutral-400;
    border-radius: 50%;
    display: flex;
    height: 40px;
    justify-content: center;
    padding: 10px;
    position: relative;
    width: 40px;

    &--pending {
      animation: Rotate 2500ms linear infinite;
    }

    &--error {
      color: $neutral-50;
    }

    &--number {
      color: $neutral-700;
      font-size: 14px;
    }
  }

  &__label {
    -webkit-box-orient: vertical;
    color: inherit;
    display: -webkit-box;
    font-size: 14px;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
  }

  &__line {
    border-radius: 6px;
    height: 4px;
    left: 50%;
    position: absolute;
    top: 15%;
    width: 100%;
  }

  &__line--completed {
    border: 2px solid $green-900;
  }

  &__line--pending {
    border: 2px solid $blue-900;
  }

  &__line--default {
    border: 2px solid $neutral-400;
  }

  &__line--error {
    border: 2px solid $red-900;
  }
}
</style>
