<script setup lang="ts">
import { Ref, ref } from 'vue';

import { useViewStore } from '@stores/view';
import { PageNames } from '@viewModels/enums';
import { IconName, IconStyle } from '@viewModels/heroIcons';

import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import ViewCard from '@components/cards/shared/ViewCard.vue';
import Directory from '@components/directory/Directory.vue';
import ProjectInformationForm from '@components/project/ProjectInformationForm.vue';
import SubHeader from '@components/SubHeader.vue';
import EmptyState from '@layouts/EmptyState.vue';

/**
 * The views store to list the p
 */
const viewStore = useViewStore();

/**
 * We create a set of wizard states to minimalize the registration process
 */
enum NavState {
  Main = 0,
  AddToViewCollection = 1,
  RemoveFromViewCollection = 2,
  EditName = 3,
}

/**
 * The current nav state
 */
const currentNavState: Ref = ref<NavState>(NavState.Main);

async function onEditName(): Promise<void> {
  throw new Error('NO LONGER IMPLEMENTED DUE TO MIGRATION');
}

// async function onRemoveFromViewCollection(resource: Array<Object>) {
//     throw new Error("NO LONGER IMPLEMENTED DUE TO MIGRATION")
// }

// async function onAddToViewCollection(resource: Array<Object>) {
//     throw new Error("NO LONGER IMPLEMENTED DUE TO MIGRATION")
// }
</script>

<template>
  <SubHeader heading="Projects"
             level="2">
    <template #buttons>
      <ButtonComponent :is-block-btn="true"
                       :disabled="true"
                       :to="{ name: PageNames.ProjectCreate }"
                       class="button">
        New Project
      </ButtonComponent>
    </template>
  </SubHeader>

  <ContainerCard>
    <!-- I don't think these should be here, adding a new project should be in the header -->
    <!--     <div class="row">
      <div class="col-3">
        <button type="button" class="btn btn-secondary" style="width: 100%" @click="nav(0)">Main</button>
      </div>
      <div class="col-3">
        <button type="button" class="btn btn-secondary" style="width: 100%" @click="nav(1)">Add Views</button>
      </div>
      <div class="col-3">
        <button type="button" class="btn btn-secondary" style="width: 100%" @click="nav(2)">Remove View</button>
      </div>
      <div class="col-3">
        <button type="button" class="btn btn-secondary" style="width: 100%" @click="nav(3)">Edit Name</button>
      </div>
      <div class="col-12">
        <hr />
      </div>
    </div> -->

    <div v-if="currentNavState === NavState.Main">
      <Suspense>
        <Directory :object-async-list-function="viewStore.obtainViewList"
                   :object-collection-reference="viewStore.viewCollectionRef.data">
          <!-- We apply the type of card we want to add -->
          <template #default="scope">
            <!-- @vue-ignore -->
            <ViewCard :resource="scope.resource" />
          </template>
        </Directory>
      </Suspense>
    </div>
    <div v-else-if="currentNavState === NavState.EditName">
      <ProjectInformationForm :submit-button-text="'Update'"
                              @on-submit="onEditName" />
    </div>
    <div v-else-if="currentNavState === NavState.AddToViewCollection">
      <p>Add View</p>
      <!-- We suspend the async dependencies -->
      <Suspense>
        <!-- We configure the directory i.e. list of items -->
        <template #default>
          <!-- <SelectableDirectory 
                    :object-async-list-function="viewStore.listUnboundViews"
                    :object-collection-reference="viewStore.viewCollectionRef"
                    @on-selection-complete="onAddToViewCollection">
                </SelectableDirectory> -->
        </template>

        <!-- We callback if there are no projects to load or it takes sometime -->
        <template #fallback>
          <!-- We show an empty state -->
          <EmptyState heading-text="No views found"
                      :icon-name="IconName.PhotoIcon"
                      :icon-style="IconStyle.Outline" />
        </template>
      </Suspense>
    </div>
    <div v-else-if="currentNavState === NavState.RemoveFromViewCollection">
      <p>Remove View</p>
      <!-- We suspend the async dependencies -->
      <Suspense>
        <!-- We configure the directory i.e. list of items -->
        <template #default>
          <!-- <SelectableDirectory 
                        :object-async-list-function="viewStore.listViewsByProject"
                        :object-collection-reference="viewStore.viewCollectionRef"
                        @on-selection-complete="onRemoveFromViewCollection">
                    </SelectableDirectory> -->
        </template>

        <!-- We callback if there are no projects to load or it takes sometime -->
        <template #fallback>
          <!-- We show an empty state -->
          <EmptyState heading-text="No views found"
                      :icon-name="IconName.PhotoIcon"
                      :icon-style="IconStyle.Outline" />
        </template>
      </Suspense>
    </div>
  </ContainerCard>
</template>
